import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import modernBuilding3 from '../img/HO/Office_1.jpg';
import modernBuilding4 from '../img/HO/Office_2.jpg';


function PhotoGalleryHO() {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleIconClick = (index) => {
    setSelectedPhotoIndex(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const largePhotos = [
    { src: modernBuilding3, caption: '' },
    { src: modernBuilding4, caption: '' },
    
    // ...
  ];

  const thumbnailIcons = [
    { src: modernBuilding3, caption: 'Caption for Thumbnail Icon 1' },
    { src: modernBuilding4, caption: 'Caption for Thumbnail Icon 2' },
    
    // ...
  ];

  useEffect(() => {
    // Add event listener to prevent right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []); 

  

  return (
    <div className="thumbnail-icons-container">
      {thumbnailIcons.map((thumbnail, index) => (
        <div key={index} onClick={() => handleIconClick(index)} className="thumbnail-icon">
          <img src={thumbnail.src} alt={`Thumbnail Icon ${index + 1}`} />
        </div>
      ))}

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body className="modal-body-horizontal">
          <div className="modal-image-container">
            <img src={largePhotos[selectedPhotoIndex].src} alt={`Large Photo ${selectedPhotoIndex + 1}`} />
            <p>{largePhotos[selectedPhotoIndex].caption}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PhotoGalleryHO;
