import React from 'react';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useParams, useNavigate } from "react-router-dom";

import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';



import modernBuildingImg from "../img/Covers/Residence_Cover IMG Resized.jpg";
import villaPoolImg from "../img/Covers/Spero_Cover IMG Resized.jpg";
import redHouseImg from "../img/Covers/Office_Cover IMG Resized.jpg";
import modernHouseImg from "../img/NL/Tribes_Cover IMG 2.jpg";
import whiteHouseImg from "../img/Covers/RPD_Cover Image.jpg";
import artCenterImg from "../img/Covers/UP_CoverIMG.jpg";
import condo from "../img/Covers/CD_Cover IMG.jpg";

import Footer from '../components/Footer';


function HomePage() {

    useEffect(() => {
        // Add event listener to prevent right-click context menu
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
    
        document.addEventListener('contextmenu', handleContextMenu);
    
        return () => {
          // Remove the event listener when the component unmounts
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }, []); 
    return (
        <div>
            <a href="#main" className="visually-hidden-focusable">Skip to main content</a>
            <header className="sticky-top ">
                <nav className="navbar navbar-dark navbar-expand-lg bg-white " aria-label="Main">
                    <div className="container">
                        <Link to="/" className="navbar-brand d-inline-flex text-uppercase text-black">Design by Lohitha<i className="material-icons ms-2" aria-hidden="true"></i></Link>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="material-icons icon-2x" aria-hidden="true">menu</i>
                        </button>
                        <div className="collapse navbar-collapse text-uppercase" id="navbar">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item text-black">
                                    <Link to="/aboutme" className="nav-link text-black">About Me</Link>
                                </li>
                                <li className="nav-item">
                                    <ScrollLink
                                        to="projectsSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        className="nav-link text-black"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Projects
                                    </ScrollLink>
                                </li>

                                <li className="nav-item">
                                    <ScrollLink
                                        to="contactSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        className="nav-link text-black"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Contact
                                    </ScrollLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <main id="main">
                {/* Header */}
                <div className="container py-5 py-lg-6 mb-6">
                    <h1 className="display-3 mb-4 text-center">Architectural Projects</h1>
                    <div className="col-lg-8 mx-auto">
                        <p className="lead font-serif text-center">
                            Designing spaces that <span className="text" style={{ color: '#000' }}>inspire</span>, <span className="text" style={{ color: '#000' }}>shape</span>, and <span className="text" style={{ color: '#000' }}>transcend</span>.
                        </p>
                    </div>
                </div>



                {/* Projects CHANGE FROM COL-XL-4 TO COL-XL-6 TO MAKE IT 2X2 INSTEAD OF 3X2 */}
                <div className="bg-white text-body-emphasis" id="projectsSection">
                    <div className="container-fluid pt-2 pb-5 pb-lg-6">
                        <ul className="row list-unstyled gx-4 gy-4 mt-n6 px-xl-4">
                            {/* Project 1 */}
                            <li className="col-sm-6 col-xl-6 custom-gap">
                                <figure className="img-scale">
                                    <div className="img-wrap mb-3">
                                        <img src={condo} width="800" height="800" alt="White and grey concrete building near swimming pool" className="img-fluid" />
                                    </div>
                                    <figcaption className="h5 fw-normal">
                                        <Link to="/condo" className="stretched-link link-body-emphasis">A Condo in the City</Link>
                                    </figcaption>
                                </figure>
                            </li>
                            <li className="col-sm-6 col-xl-6 custom-gap">
                                <figure className="img-scale">
                                    <div className="img-wrap mb-3">
                                        <img src={modernBuildingImg} width="800" height="800" alt="Windowpanes at the building" className="img-fluid" />
                                    </div>
                                    <figcaption className="h5 fw-normal">
                                        <Link to="/interior" className="stretched-link link-body-emphasis">Residence Interiors</Link>
                                    </figcaption>
                                </figure>
                            </li>
                            <li className="col-sm-6 col-xl-6 custom-gap">
                                <figure className="img-scale">
                                    <div className="img-wrap mb-3">
                                        <img src={villaPoolImg} width="800" height="800" alt="White and grey concrete building near swimming pool" className="img-fluid" />
                                    </div>
                                    <figcaption className="h5 fw-normal">
                                        <Link to="/hospital" className="stretched-link link-body-emphasis">Spero Hospital</Link>
                                    </figcaption>
                                </figure>
                            </li>
                            {/* Project 2 */}
                            
                            {/* Project 3 */}
                            <li className="col-sm-6 col-xl-6 custom-gap">
                                <figure className="img-scale">
                                    <div className="img-wrap mb-3">
                                        <img src={redHouseImg} width="800" height="800" alt="Red wooden house near sea" className="img-fluid" />
                                    </div>
                                    <figcaption className="h5 fw-normal">
                                        <Link to="/office" className="stretched-link link-body-emphasis">Home Office</Link>
                                    </figcaption>
                                </figure>
                            </li>

                            {/* Project 5 */}
                            <li className="col-sm-6 col-xl-6 custom-gap">
                                <figure className="img-scale">
                                    <div className="img-wrap mb-3">
                                        <img src={whiteHouseImg} width="800" height="800" alt="White concrete house near green tree" className="img-fluid" />
                                    </div>
                                    <figcaption className="h5 fw-normal">
                                        <Link to="/rpd" className="stretched-link link-body-emphasis">Recycling plant design</Link>
                                    </figcaption>
                                </figure>
                            </li>
                            {/* Project 4 */}
                            <li className="col-sm-6 col-xl-6 custom-gap">
                                <figure className="img-scale">
                                    <div className="img-wrap mb-3">
                                        <img src={modernHouseImg} width="800" height="800" alt="High-rise buildings" className="img-fluid" />
                                    </div>
                                    <figcaption className="h5 fw-normal">
                                        <Link to="/centre" className="stretched-link link-body-emphasis">Native Group Life Experience Center</Link>
                                    </figcaption>
                                </figure>
                            </li>

                            {/* Project 6 */}
                            <li className="col-sm-6 col-xl-6 custom-gap">
                                <figure className="img-scale">
                                    <div className="img-wrap mb-3">
                                        <img src={artCenterImg} width="800" height="800" alt="White modern building" className="img-fluid" />
                                    </div>
                                    <figcaption className="h5 fw-normal">
                                        <Link to="/up" className="stretched-link link-body-emphasis">Urban Planning</Link>
                                    </figcaption>
                                </figure>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Icon blocks */}
                <div className="container py-5 py-lg-6">
                    <div className="row gy-5">
                        <div className="col-lg-4">
                            <i className="material-icons icon-2x text-primary mb-3" aria-hidden="true"></i>
                            <h2 className="h4 fw-normal">Design</h2>
                            <p className="fw-light mb-0">
                                My design philosophy focuses on creating visually stunning and functional spaces that surpass expectations.
                            </p>
                        </div>
                        <div className="col-lg-4">
                            <i className="material-icons icon-2x text-primary mb-3" aria-hidden="true"></i>
                            <h2 className="h4 fw-normal">Approach</h2>
                            <p className="fw-light mb-0">
                                I approach every project with meticulous attention to detail, ensuring a seamless and collaborative process from concept to completion.
                            </p>
                        </div>
                        <div className="col-lg-4">
                            <i className="material-icons icon-2x text-primary mb-3" aria-hidden="true"></i>
                            <h2 className="h4 fw-normal">Sustainability</h2>
                            <p className="fw-light mb-0">
                                Sustainability is at the core of my practice, integrating eco-conscious strategies to minimize environmental impact and create a greener future.
                            </p>
                        </div>
                    </div>
                </div>


                {/* Call to action */}
                <div className="text-bg-dark text-center" id="contactSection">
                    <div className="container py-5 py-lg-6">
                        <h2>Let's design your perfect vision</h2>
                        <p className="lead mb-4">Transform your dream into reality</p>
                        <a className="btn btn-light btn-sm">lohithasmritiks@gmail.com</a>
                    </div>
                </div>

            </main>

            <Footer/>

        </div>
    );
}

export default HomePage;
