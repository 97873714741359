import { Container } from 'react-bootstrap'

import { HashRouter as Router, Route, Routes } from 'react-router-dom'

import './css/style.css'

import HomePage from './screens/HomePage';
import SperoDetail from './screens/SperoDetail';

import AboutMe from './screens/AboutMe';
import RI from './screens/RIDetail';
import HO from './screens/HODetail';
import NL from './screens/NLDetail';
import Rpd from './screens/rpdDetail';
import UpDetail from './screens/UpDetail';
import Condo from './screens/Condo';

function App() {
  return (
    <div>

      <Router>

        <main className="py-5 ">
          <Container>
            <Routes>
              <Route path='/' element={<HomePage />} exact />
              <Route path='/hospital' element={<SperoDetail />} exact />
              <Route path='/interior' element={<RI />} exact />
              <Route path='/aboutme' element={<AboutMe />} exact />
              <Route path='/rpd' element={<Rpd />} exact />
              <Route path='/office' element={<HO />} exact />
              <Route path='/centre' element={<NL />} exact />
              <Route path='/up' element={<UpDetail />} exact />
              <Route path='/condo' element={<Condo />} exact />


            </Routes>
          </Container>
        </main>

      </Router></div>

  );
}

export default App;
