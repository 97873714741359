import React from 'react'

function Footer() {
    return (
        <div>

            <footer className="text-center py-4 text-muted">
                &copy; {new Date().getFullYear()} Design by Lohitha. All rights reserved.
            </footer>

        </div>
    )
}

export default Footer
