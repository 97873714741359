import React from 'react';
import { Link } from "react-router-dom";

import { Link as ScrollLink } from 'react-scroll';

import modernBuilding1 from "../img/Covers/Loh.jpeg";

import Footer from '../components/Footer';

function AboutMe() {
  return (
    <div>
      <nav className="navbar navbar-dark navbar-expand-lg bg-white " aria-label="Main">
        <div className="container">
          <Link to="/" className="navbar-brand d-inline-flex text-uppercase text-black">Design by Lohitha<i className="material-icons ms-2" aria-hidden="true"></i></Link>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
            <i className="material-icons icon-2x" aria-hidden="true">menu</i>
          </button>
          <div className="collapse navbar-collapse text-uppercase" id="navbar">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item text-black">
                <Link to="/aboutme" className="nav-link text-black">About Me</Link>
              </li>
              <li className="nav-item text-black">
                <Link to="/" className="nav-link text-black">Projects</Link>
              </li>

              <li className="nav-item text-black">
                <Link to="/" className="nav-link text-black">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-4">
            <img src={modernBuilding1} alt="Profile" className="img-fluid rounded-circle mb-4" />
          </div>
          <div className="col-lg-8">
            <h1 className="display-4 mb-4">Lohitha Smriti</h1>

            <p className='text-muted'>Welcome to my architect portfolio! As a dedicated architect, my passion lies in modern luxury residences. With a keen eye for detail, I craft spaces that evoke a sense of elegance and serenity through the use of neutral colors. My design philosophy centers around building communities that thrive on the harmony of architecture and nature. I strive to create spaces that not only inspire but also shape the way people experience their surroundings. Each project I undertake aims to transcend boundaries, blending functionality and aesthetics into a seamless whole. Join me on this journey of transformative design that leaves an indelible mark on the world.</p>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  )
}

export default AboutMe;
