import { useState, useEffect } from 'react';
import { Carousel2 } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Modal } from 'react-bootstrap';
import { Container, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import PhotoGalleryRPD from '../components/PhotoGalleryRPD.js';

import modernBuilding1 from '../img/UP/UP_01 .jpg';
// import modernBuilding2 from '../img/UP/UP_02.jpg';
// import modernBuilding3 from '../img/RPD/RPD_5.jpg';
// import modernBuilding4 from '../img/RPD/RPD_6.jpg'; //this
// import modernBuilding5 from '../img/RPD/RPD_7.jpg'; //this
// import modernBuilding6 from '../img/RPD/RPD_8.jpg'; //this
// import modernBuilding7 from '../img/RPD/RPD_9.jpg';
// import modernBuilding8 from '../img/RPD/RPD_10.jpg';
// import modernBuilding9 from '../img/RPD/RPD_11.jpg';
// import modernBuilding10 from '../img/Spero/Spero_10 Resized.jpg';
// import modernBuilding11 from '../img/Spero/Spero_11.jpg';
// import modernBuilding12 from '../img/Spero/Spero_12 Resized.jpg';

import Footer from '../components/Footer.js';




function UpDetail() {

    const [selectedPhoto, setSelectedPhoto] = useState(0);

    const handleCarouselSlide = (selectedIndex) => {
        setSelectedPhoto(selectedIndex);
    };

    useEffect(() => {
        // Add event listener to prevent right-click context menu
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
    
        document.addEventListener('contextmenu', handleContextMenu);
    
        return () => {
          // Remove the event listener when the component unmounts
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }, []); 


    return (
        <div>
            <a href="/" className="visually-hidden-focusable">Skip to main content</a>
            <header className="sticky-top ">
                <nav className="navbar navbar-dark navbar-expand-lg bg-white " aria-label="Main">
                    <div className="container">
                        <Link to="/" className="navbar-brand d-inline-flex text-uppercase text-black">Design by Lohitha<i className="material-icons ms-2" aria-hidden="true"></i></Link>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="material-icons icon-2x" aria-hidden="true">menu</i>
                        </button>
                        <div className="collapse navbar-collapse text-uppercase" id="navbar">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item text-black">
                                    <Link to="/aboutme" className="nav-link text-black">About Me</Link>
                                </li>
                                <li className="nav-item text-black">
                                    <Link to="/" className="nav-link text-black">Projects</Link>
                                </li>

                                <li className="nav-item text-black">
                                    <Link to="/" className="nav-link text-black">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <main id="main">
                {/* Header */}
                <div className="container py-5 py-lg-6">
                    <h6 className="display-6 mb-4 mb-md-5">Urban Planning</h6>
                </div>


                {/* Project */}
                <div className="bg-white text-dark pb-5">
                    <Container fluid="xl" className="py-2 px-0">
                        <Carousel
                            id="slideshow"
                            className="carousel slide mt-n6 mx-xl-n4 mb-5"
                            indicators={false}
                            controls
                            activeIndex={selectedPhoto}
                            onSelect={handleCarouselSlide}
                            prevIcon={<FiChevronLeft size={42} color="black" />}
                            nextIcon={<FiChevronRight size={42} color="black" />}
                        >
                          
                                <Carousel.Item>
                                    <img src={modernBuilding1} className="d-block mx-auto w-50" alt="First slide" />
                                </Carousel.Item>
                                {/* <Carousel.Item>
                                    <img src={modernBuilding2} className="d-block mx-auto w-50" alt="Second slide" />
                                </Carousel.Item> */}
                                {/* <Carousel.Item>
                                    <img src={modernBuilding3} className="d-block mx-auto w-50" alt="Third slide" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={modernBuilding4} className="d-block mx-auto w-50" alt="Third slide" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={modernBuilding5} className="d-block mx-auto w-50" alt="Third slide" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={modernBuilding6} className="d-block mx-auto w-50" alt="Third slide" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={modernBuilding7} className="d-block mx-auto w-50" alt="Third slide" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={modernBuilding8} className="d-block mx-auto w-50" alt="Third slide" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={modernBuilding9} className="d-block mx-auto w-50" alt="Third slide" />
                                </Carousel.Item> */}
                           

                            {/* <Carousel.Item>
                                <img src={modernBuilding10} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
                            {/* <Carousel.Item>
                                <img src={modernBuilding11} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
                            {/* <Carousel.Item>
                                <img src={modernBuilding12} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}

                        </Carousel>
                    </Container>

                    <Container>
                        <div className="row mb-5">
                            {/* <div className="col-lg-4">
                                <dl className="mt-1">
                                    <dt className="fw-light">Project Status</dt>
                                    <dd>Construction in progress</dd>
                                    <dt className="fw-light">Location</dt>
                                    <dd>Coimbatore, Tamil Nadu, India</dd>
                                    <dt className="fw-light">SITE  AREA</dt>
                                    <dd>1.3 Acres (56628 Sq.Ft)</dd>
                                    <dt className="fw-light">FLOORS</dt>
                                    <dd>G+3 </dd>
                                </dl>
                            </div> */}
                            {/* <div className="col-lg-8 col-xxl-7">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu <a href="#/">fugiat nulla</a> pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div> */}
                            {/* <div className="col-lg-8 col-xxl-7">
                                {selectedPhoto === 0 && (

                                    <div>
                                        <h5>Photo 1: Private Multi-Speciality Hospital</h5>
                                        <p className="text-muted">Located in the outskirts of the city</p>
                                        <ul>
                                            <li>Bullet point 1</li>
                                            <li>Bullet point 2</li>
                                            <li>Bullet point 3</li>
                                           
                                        </ul>
                                    </div>
                                )}
                                {selectedPhoto === 1 && (
                                    <div>
                                        <h5>Photo 2: Private Multi-Speciality Hospital</h5>
                                        <p className="text-muted">Located in the outskirts of the city</p>
                                        <ul>
                                            <li>Bullet point 1</li>
                                            <li>Bullet point 2</li>
                                            <li>Bullet point 3</li>
                                            
                                        </ul>
                                    </div>
                                )}
                                

                                
                            </div> */}
                        </div>

                        {/* Projects navigation */}
                        <div className="d-flex justify-content-between align-content-center lh-1">
                            <Link to="#" className="link-body-emphasis" data-bs-toggle="tooltip" title="Previous project">

                                <span className="visually-hidden">Previous project</span>
                            </Link>
                            <Link to="/portfolio-1" className="link-body-emphasis" data-bs-toggle="tooltip" title="Projects page">

                            </Link>
                            <Link to="#" className="link-body-emphasis" data-bs-toggle="tooltip" title="Next project">

                                <span className="visually-hidden">Next project</span>
                            </Link>
                        </div>
                    </Container>
                </div>
            </main>

            <div className="text-center">
                {/* Other components */}
                {/* <h2>Technical Drawings</h2> */}
                
                {/* Other components */}
            </div>


            <Footer/>
        </div>
    );
}

export default UpDetail;

