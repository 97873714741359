import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import modernBuilding3 from '../img/Spero/Spero_3.jpg';
import modernBuilding4 from '../img/Spero/Spero_4.jpg';
import modernBuilding5 from '../img/Spero/Spero_5.jpg';
import modernBuilding6 from '../img/Spero/Spero_6.jpg';
import modernBuilding7 from '../img/Spero/Spero_7.jpg';
import modernBuilding9 from '../img/Spero/Spero_9.jpg';
import modernBuilding11 from '../img/Spero/Spero_11.jpg';

function PhotoGallery() {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [thumbnailsPerRow, setThumbnailsPerRow] = useState(4); 

  const handleIconClick = (index) => {
    setSelectedPhotoIndex(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  

  const largePhotos = [
    { src: modernBuilding4, caption: '' },
    { src: modernBuilding5, caption: '' },
    { src: modernBuilding6, caption: '' },
    { src: modernBuilding7, caption: '' },
    { src: modernBuilding3, caption: '' },
    { src: modernBuilding9, caption: '' },
    
    // ...
  ];

  const thumbnailIcons = [
    { src: modernBuilding4, caption: '' },
    { src: modernBuilding5, caption: '' },
    { src: modernBuilding6, caption: '' },
    { src: modernBuilding7, caption: '' },
    { src: modernBuilding3, caption: '' },
    { src: modernBuilding9, caption: '' },
    
    // ...
  ];

  useEffect(() => {
    // Add event listener to prevent right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []); 

  

  return (
    <div className="thumbnail-icons-container">
      <div className="thumbnail-icons-container">
                {Array.from({ length: 3 }, (_, rowIndex) => (
                    <div key={rowIndex} className="row custom-row-spacing">
                        {thumbnailIcons.slice(rowIndex * 3, rowIndex * 3 + 3).map((thumbnail, index) => (
                            <div key={index} className="col-md-4">
                                <div onClick={() => handleIconClick(rowIndex * 3 + index)} className="thumbnail-icon thumbnail-with-border">
                                    <img src={thumbnail.src} alt={`Thumbnail Icon ${rowIndex * 3 + index + 1}`} className="thumbnail-size" />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body className="modal-body-horizontal">
          <div className="modal-image-container">
            <img src={largePhotos[selectedPhotoIndex].src} alt={`Large Photo ${selectedPhotoIndex + 1}`} />
            <p>{largePhotos[selectedPhotoIndex].caption}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PhotoGallery;
