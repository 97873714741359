import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import modernBuilding3 from '../img/condo/FP-CD_06 Floor Plan.jpg';
import modernBuilding4 from '../img/condo/FP-CD_07 Elevation Index.jpg';
import modernBuilding5 from '../img/condo/FP-CD_08 Living Room Elevation A.jpg';
import modernBuilding6 from '../img/condo/FP-CD_09 Living Room Elevation B.jpg';
import modernBuilding7 from '../img/condo/FP-CD_10 Living Room Elevation C.jpg';
import modernBuilding9 from '../img/condo/FP-CD_11 Living Room Elevation D.jpg';
import modernBuilding11 from '../img/condo/FP-CD_12 Balcony Elevation A.jpg';
import modernBuilding12 from '../img/condo/FP-CD_13 Balcony Elevation C.jpg';
import modernBuilding13 from '../img/condo/FP-CD_14 Balcony Elevation D.jpg';


function PhotoGalleryC() {
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [thumbnailsPerRow, setThumbnailsPerRow] = useState(4);

    const handleIconClick = (index) => {
        setSelectedPhotoIndex(index);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };



    const largePhotos = [
        { src: modernBuilding4, caption: 'Floor Plan' },
        { src: modernBuilding5, caption: 'Elevation Index' },
        { src: modernBuilding6, caption: 'Living Room Elevation A' },
        { src: modernBuilding7, caption: 'Living Room Elevation B' },
        { src: modernBuilding3, caption: 'Living Room Elevation C' },
        { src: modernBuilding9, caption: 'Living Room Elevation D' },
        { src: modernBuilding11, caption: 'Balcony Elevation A' },
        { src: modernBuilding12, caption: 'Balcony Elevation B' },
        { src: modernBuilding13, caption: 'Balcony Elevation C' },
        // ...
    ];

    const thumbnailIcons = [
        { src: modernBuilding4, caption: '' },
        { src: modernBuilding5, caption: '' },
        { src: modernBuilding6, caption: '' },
        { src: modernBuilding7, caption: '' },
        { src: modernBuilding3, caption: '' },
        { src: modernBuilding9, caption: '' },
        { src: modernBuilding11, caption: '' },
        { src: modernBuilding12, caption: '' },
        { src: modernBuilding13, caption: '' },
        // ...
    ];

    useEffect(() => {
        // Add event listener to prevent right-click context menu
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            // Remove the event listener when the component unmounts
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);



    return (
        <div className="thumbnail-icons-container">
            <div className="thumbnail-icons-container">
                {Array.from({ length: 3 }, (_, rowIndex) => (
                    <div key={rowIndex} className="row custom-row-spacing">
                        {thumbnailIcons.slice(rowIndex * 3, rowIndex * 3 + 3).map((thumbnail, index) => (
                            <div key={index} className="col-md-4">
                                <div onClick={() => handleIconClick(rowIndex * 3 + index)} className="thumbnail-icon thumbnail-with-border">
                                    <img src={thumbnail.src} alt={`Thumbnail Icon ${rowIndex * 3 + index + 1}`} className="thumbnail-size" />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Body className="modal-body-horizontal">
                    <div className="modal-image-container">
                        <img src={largePhotos[selectedPhotoIndex].src} alt={`Large Photo ${selectedPhotoIndex + 1}`} />
                        <p>{largePhotos[selectedPhotoIndex].caption}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default PhotoGalleryC;
