import { useState } from 'react';
import { Carousel2 } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Modal } from 'react-bootstrap';
import { Container, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import PhotoGalleryRPD from '../components/PhotoGalleryRPD.js';

import modernBuilding1 from '../img/RPD/RPD_3.jpg';
import modernBuilding2 from '../img/RPD/RPD_4.jpg';
import modernBuilding3 from '../img/RPD/RPD_5.jpg';
import modernBuilding4 from '../img/RPD/RPD_6.jpg'; //this
import modernBuilding5 from '../img/RPD/RPD_7.jpg'; //this
import modernBuilding6 from '../img/RPD/RPD_8.jpg'; //this
import modernBuilding7 from '../img/RPD/RPD_9.jpg';
import modernBuilding8 from '../img/RPD/RPD_10.jpg';
import modernBuilding9 from '../img/RPD/RPD_11.jpg';
import modernBuilding10 from '../img/Spero/Spero_10 Resized.jpg';
import modernBuilding11 from '../img/Spero/Spero_11.jpg';
import modernBuilding12 from '../img/Spero/Spero_12 Resized.jpg';




function Rpd() {

    const [selectedPhoto, setSelectedPhoto] = useState(0);

    const handleCarouselSlide = (selectedIndex) => {
        setSelectedPhoto(selectedIndex);
    };


    return (
        <div>
            <a href="/" className="visually-hidden-focusable">Skip to main content</a>
            <header className="sticky-top ">
                <nav className="navbar navbar-dark navbar-expand-lg bg-white " aria-label="Main">
                    <div className="container">
                        <Link to="/" className="navbar-brand d-inline-flex text-uppercase text-black">Design by Lohitha<i className="material-icons ms-2" aria-hidden="true"></i></Link>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="material-icons icon-2x" aria-hidden="true">menu</i>
                        </button>
                        <div className="collapse navbar-collapse text-uppercase" id="navbar">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item text-black">
                                    <Link to="/aboutme" className="nav-link text-black">About Me</Link>
                                </li>
                                <li className="nav-item text-black">
                                    <Link to="/" className="nav-link text-black">Projects</Link>
                                </li>

                                <li className="nav-item text-black">
                                    <Link to="/" className="nav-link text-black">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <main id="main">
                {/* Header */}
                <div className="container py-5 py-lg-6">
                    <h6 className="display-6 mb-4 mb-md-5">Recycling plant design</h6>
                </div>


                {/* Project */}
                <div className="bg-white text-dark pb-5">
                    <Container fluid="xl" className="py-2 px-0">
                        <Carousel
                            id="slideshow"
                            className="carousel slide mt-n6 mx-xl-n4 mb-5"
                            indicators={false}
                            controls
                            activeIndex={selectedPhoto}
                            onSelect={handleCarouselSlide}
                            prevIcon={<FiChevronLeft size={42} color="black" />}
                            nextIcon={<FiChevronRight size={42} color="black" />}
                        >

                            <Carousel.Item>
                                <img src={modernBuilding1} className="d-block mx-auto w-50" alt="First slide" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={modernBuilding2} className="d-block mx-auto w-50" alt="Second slide" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={modernBuilding3} className="d-block mx-auto w-50" alt="Third slide" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={modernBuilding4} className="d-block mx-auto w-50" alt="Third slide" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={modernBuilding5} className="d-block mx-auto w-50" alt="Third slide" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={modernBuilding6} className="d-block mx-auto w-50" alt="Third slide" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={modernBuilding7} className="d-block mx-auto w-50" alt="Third slide" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={modernBuilding8} className="d-block mx-auto w-50" alt="Third slide" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={modernBuilding9} className="d-block mx-auto w-50" alt="Third slide" />
                            </Carousel.Item>


                            {/* <Carousel.Item>
                                <img src={modernBuilding10} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
                            {/* <Carousel.Item>
                                <img src={modernBuilding11} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
                            {/* <Carousel.Item>
                                <img src={modernBuilding12} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}

                        </Carousel>
                    </Container>

                    <Container>
                        <div className="row mb-5">
                            <div className="col-lg-4">
                                <dl className="mt-1">
                                    <dt className="fw-light">PROJECT STATUS</dt>
                                    <dd>Concept</dd>
                                    <dt className="fw-light">Location</dt>
                                    <dd>Bangalore, India</dd>
                                    
                                </dl>
                            </div>
                            {/* <div className="col-lg-8 col-xxl-7">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu <a href="#/">fugiat nulla</a> pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div> */}
                            <div className="col-lg-8 col-xxl-7">
                                {selectedPhoto === 0 && (
                                    <div>
                                        <h5>View 1</h5>
                                        <p className="text-muted">The site is located within city limits of Bangalore, Karnataka.</p>
                                        <ul>
                                            <li>The brief is to design a government-run recycling Plant with the aim to reduce the space from lying as a redundant spot mainly due to the function within.</li>
                                            <li>The project connects its feeling and nature of liminality along with the other assumed sections, groups, aspects that are kept secluded.</li>
                                        </ul>
                                    </div>
                                )}
                                {selectedPhoto === 1 && (
                                    <div>
                                        <h5>View 2</h5>
                                        <ul>
                                            <li>The circular building shown above houses a nursery, a daily market and a senior citizens' entertainment facility.</li>
                                            <li>This is offered with the goal of giving professionals places to work comfortably while their children are entertained or senior family members have a place to go for both leisure and passive income generation through hands-on activities.</li>
                                        </ul>
                                    </div>
                                )}
                                {selectedPhoto === 2 && (
                                    <div>
                                        <h5>View 3</h5>
                                        <p className="text-muted">An amphitheater setup is shown in the illustration below.</p>
                                        <ul>
                                            <li>The idea was put forth on the site to boost the involvement of the youth in building a location that is freely accessible to every individual, at all instances, for any and all needs.</li>
                                            <li>The intent of this facility was meant to function as a co-working area, an arena for performances, and an intersection for various individuals to demonstrate their rights and transcend the "liminal rut" that society had encased them in.</li>
                                        </ul>
                                    </div>
                                )}
                                {selectedPhoto === 3 && (
                                    <div>
                                        <h5>View 4</h5>
                                        <li>The daily market increases the number of visitors to the location where liminality is lessened by conventional settings.</li>
                                    </div>
                                )}
                                {selectedPhoto === 4 && (
                                    <div>
                                        <h5>View 5</h5>
                                        <li>To combat the prevailing mental picture of dust and browns that comes to mind when thinking of a recycling unit, a water pond has been added to the property.</li>
                                        <li>It has trees and moss-covered rocks to create a biophilic atmosphere.</li>
                                    </div>
                                )}
                                {selectedPhoto === 5 && (
                                    <div>
                                        <h5>View 6</h5>
                                        <li>Benefits of the water body also include reducing the pollution that already exists and is getting worse on the job site as a result of the smoke from the furnace and the large machinery and vehicles used to carry garbage.</li>
                                    </div>
                                )}
                                {selectedPhoto === 6 && (
                                    <div>
                                        <h5>View 7</h5>
                                        <li>The water body is surrounded by a walking track.</li>
                                        <li>The main motive behind this was to cater to the objective and concept of the project which is to ensure an increased footfall both in terms of varied groups as well as age groups.</li>
                                    </div>
                                )}
                                {selectedPhoto === 7 && (
                                    <div>
                                        <h5>View 8</h5>
                                        <li>Walkways for all social gatherings to foster community.</li>
                                    </div>
                                )}
                                {selectedPhoto === 8 && (
                                    <div>
                                        <h5>View 9</h5>
                                        <li>The centerpiece of the entire facility is an exhibition area; this is where the worlds of the general public and recycling unit employees collide.</li>
                                        <li>In this area, crafts and particular items that are manufactured to "make wealth out of waste" are on sale.</li>
                                        <li>This place is intended to be kid-friendly, where neighborhood kids, kids of plant workers, ladies, and senior citizens can work here as part-time apprentices and make their crafts.</li>
                                    </div>
                                )}


                                {/* Add more conditions for additional photos */}
                            </div>
                        </div>

                        {/* Projects navigation */}
                        <div className="d-flex justify-content-between align-content-center lh-1">
                            <Link to="#" className="link-body-emphasis" data-bs-toggle="tooltip" title="Previous project">

                                <span className="visually-hidden">Previous project</span>
                            </Link>
                            <Link to="/portfolio-1" className="link-body-emphasis" data-bs-toggle="tooltip" title="Projects page">

                            </Link>
                            <Link to="#" className="link-body-emphasis" data-bs-toggle="tooltip" title="Next project">

                                <span className="visually-hidden">Next project</span>
                            </Link>
                        </div>
                    </Container>
                </div>
            </main>

            <div className="text-center">
                {/* Other components */}
                <h2>Technical Drawings</h2>
                <PhotoGalleryRPD />
                {/* Other components */}
            </div>


            <footer className="text-center py-4 text-muted">
                &copy; {new Date().getFullYear()} Design by Loh. All rights reserved.
            </footer>
        </div>
    );
}

export default Rpd;

