import { useState } from 'react';
import { Carousel2 } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Modal } from 'react-bootstrap';
import { Container, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import PhotoGalleryRI from '../components/PhotoGalleryRI.js';

import modernBuilding1 from '../img/RI/RES_2.jpeg';
import modernBuilding2 from '../img/RI/RES_3.jpg';
import modernBuilding3 from '../img/Spero/Spero_3 Resized.jpg';
import modernBuilding4 from '../img/Spero/Spero_4.jpg'; //this
import modernBuilding5 from '../img/Spero/Spero_5.jpg'; //this
import modernBuilding6 from '../img/Spero/Spero_6.jpg'; //this
import modernBuilding7 from '../img/Spero/Spero_7.jpg';
import modernBuilding8 from '../img/RI/RES_4.jpg';
import modernBuilding9 from '../img/Spero/Spero_9.jpg';
import modernBuilding10 from '../img/RI/RES_5.jpg';
import modernBuilding11 from '../img/Spero/Spero_11.jpg';
import modernBuilding12 from '../img/RI/RES_6.jpg';

import Footer from '../components/Footer.js';




function RI() {

    const [selectedPhoto, setSelectedPhoto] = useState(0);

    const handleCarouselSlide = (selectedIndex) => {
        setSelectedPhoto(selectedIndex);
    };


    return (
        <div>
            <a href="/" className="visually-hidden-focusable">Skip to main content</a>
            <header className="sticky-top ">
                <nav className="navbar navbar-dark navbar-expand-lg bg-white " aria-label="Main">
                    <div className="container">
                        <Link to="/" className="navbar-brand d-inline-flex text-uppercase text-black">Design by Lohitha<i className="material-icons ms-2" aria-hidden="true"></i></Link>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="material-icons icon-2x" aria-hidden="true">menu</i>
                        </button>
                        <div className="collapse navbar-collapse text-uppercase" id="navbar">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item text-black">
                                    <Link to="/aboutme" className="nav-link text-black">About Me</Link>
                                </li>
                                <li className="nav-item text-black">
                                    <Link to="/" className="nav-link text-black">Projects</Link>
                                </li>

                                <li className="nav-item text-black">
                                    <Link to="/" className="nav-link text-black">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <main id="main">
                {/* Header */}
                <div className="container py-5 py-lg-6">
                    <h6 className="display-6 mb-4 mb-md-5">Residence Interiors</h6>
                </div>


                {/* Project */}
                <div className="bg-white text-dark pb-5">
                    <Container fluid="xl" className="py-2 px-0">
                        <Carousel
                            id="slideshow"
                            className="carousel slide mt-n6 mx-xl-n4 mb-5"
                            indicators={false}
                            controls
                            activeIndex={selectedPhoto}
                            onSelect={handleCarouselSlide}
                            prevIcon={<FiChevronLeft size={42} color="black" />}
                            nextIcon={<FiChevronRight size={42} color="black" />}
                        >
                            <Carousel.Item>
                                <img
                                    src={modernBuilding1}
                                    className="d-block w-75 mx-auto"
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    src={modernBuilding2}
                                    className="d-block w-75 mx-auto"
                                    alt="Second slide"
                                />
                            </Carousel.Item>


                            <Carousel.Item>
                                <img src={modernBuilding8} className="d-block w-75 mx-auto" alt="Third slide" />
                            </Carousel.Item>

                            <Carousel.Item>
                                <img src={modernBuilding10} className="d-block w-75 mx-auto" alt="Third slide" />
                            </Carousel.Item>

                            <Carousel.Item>
                                <img src={modernBuilding12} className="d-block w-75 mx-auto" alt="Third slide" />
                            </Carousel.Item>

                        </Carousel>


                    </Container>

                    <Container>
                        <div className="row mb-5">
                            <div className="col-lg-4">
                                <dl className="mt-1">
                                    <dt className="fw-light">PROJECT STATUS</dt>
                                    <dd>Concept</dd>
                                    
                                    <dt className="fw-light">SPACES</dt>
                                    <dd>Kitchen, living, outdoors</dd>
                                    <dt className="fw-light">COLORS</dt>
                                    <dd>Neutral</dd>
                                </dl>
                            </div>
                            {/* <div className="col-lg-8 col-xxl-7">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu <a href="#/">fugiat nulla</a> pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div> */}
                            <div className="col-lg-8 col-xxl-7">
                                {selectedPhoto === 0 && (
                                    <div>
                                        <h5>View 1</h5>
                                        {/* <p className="text-muted">Located in the outskirts of the city</p> */}
                                        <p>The goal was to design a living space using the neutral colour palette of white, black, beige, and browns to produce a place that blended together and felt both luxurious as well as earthy.</p>
                                    </div>
                                )}
                                {selectedPhoto === 1 && (
                                    <div>
                                        <h5>View 2</h5>
                                        {/* <p className="text-muted">Located in the outskirts of the city</p> */}
                                        <p>The home emanates a sense of serenity and stability because to the earthy, consistent, and restrained color scheme which also leaves room for moments of exuberance.</p>
                                    </div>
                                )}
                                {selectedPhoto === 2 && (
                                    <div>
                                        <h5>View 3</h5>
                                        {/* <p className="text-muted">Located in the outskirts of the city</p> */}
                                        <p>The backyard was tucked into the L-shaped plan's inner side, allowing adequate daylight to enter the lower level's crevices and corners. Outdoor seating enhanced the views from within towards the city.</p>
                                    </div>
                                )}
                                {selectedPhoto === 3 && (
                                    <div>
                                        <h5>View 4</h5>
                                        {/* <p className="text-muted">Located in the outskirts of the city</p> */}
                                        <p>The white marble island with moss green leather chairs, white lacquer cabinetry, gold rimmed modern chandelier and the wall height glazing that is also a door creates a well lit and breezy kitchen set up.</p>
                                    </div>
                                )}
                                {selectedPhoto === 4 && (
                                    <div>
                                        <h5>View 5</h5>
                                        {/* <p className="text-muted">Located in the outskirts of the city</p> */}
                                        <p>This sit-out overlooking the city views as well as the interior of the home is adorned by a tree planter in the center, wood ceiling panel with strip lights and shrubs planted along its edges.</p>
                                    </div>
                                )}



                                {/* Add more conditions for additional photos */}
                            </div>
                        </div>

                        {/* Projects navigation */}
                        <div className="d-flex justify-content-between align-content-center lh-1">
                            <Link to="#" className="link-body-emphasis" data-bs-toggle="tooltip" title="Previous project">

                                <span className="visually-hidden">Previous project</span>
                            </Link>
                            <Link to="/portfolio-1" className="link-body-emphasis" data-bs-toggle="tooltip" title="Projects page">

                            </Link>
                            <Link to="#" className="link-body-emphasis" data-bs-toggle="tooltip" title="Next project">

                                <span className="visually-hidden">Next project</span>
                            </Link>
                        </div>
                    </Container>
                </div>
            </main>

            <div className="text-center">
                {/* Other components */}
                <h2>Technical Drawings</h2>
                <PhotoGalleryRI />
                {/* Other components */}
            </div>

            <Footer />
        </div>
    );
}

export default RI;

