import { useState } from 'react';
import { Carousel2 } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Modal } from 'react-bootstrap';
import { Container, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import PhotoGallery from '../components/PhotoGallery.js';

import modernBuilding1 from '../img/Spero/Spero_1 Resized.jpg';
import modernBuilding2 from '../img/Spero/Spero_2 Resized.jpg';
import modernBuilding3 from '../img/Spero/Spero_3 Resized.jpg';
import modernBuilding4 from '../img/Spero/Spero_4.jpg'; //this
import modernBuilding5 from '../img/Spero/Spero_5.jpg'; //this
import modernBuilding6 from '../img/Spero/Spero_6.jpg'; //this
import modernBuilding7 from '../img/Spero/Spero_7.jpg';
import modernBuilding8 from '../img/Spero/Spero_8.jpg';
import modernBuilding9 from '../img/Spero/Spero_9.jpg';
import modernBuilding10 from '../img/Spero/Spero_10 Resized.jpg';
import modernBuilding11 from '../img/Spero/Spero_11.jpg';
import modernBuilding12 from '../img/Spero/Spero_12 Resized.jpg';

import Footer from '../components/Footer.js';




function ModernBuildingPage() {

    const [selectedPhoto, setSelectedPhoto] = useState(0);

    const handleCarouselSlide = (selectedIndex) => {
        setSelectedPhoto(selectedIndex);
    };


    return (
        <div>
            <a href="/" className="visually-hidden-focusable">Skip to main content</a>
            <header className="sticky-top ">
                <nav className="navbar navbar-dark navbar-expand-lg bg-white " aria-label="Main">
                    <div className="container">
                        <Link to="/" className="navbar-brand d-inline-flex text-uppercase text-black">Design by Lohitha<i className="material-icons ms-2" aria-hidden="true"></i></Link>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="material-icons icon-2x" aria-hidden="true">menu</i>
                        </button>
                        <div className="collapse navbar-collapse text-uppercase" id="navbar">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item text-black">
                                    <Link to="/aboutme" className="nav-link text-black">About Me</Link>
                                </li>
                                <li className="nav-item text-black">
                                    <Link to="/" className="nav-link text-black">Projects</Link>
                                </li>

                                <li className="nav-item text-black">
                                    <Link to="/" className="nav-link text-black">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <main id="main">
                {/* Header */}
                <div className="container py-5 py-lg-6">
                    <h6 className="display-6 mb-4 mb-md-5">Spero Hospital</h6>
                </div>


                {/* Project */}
                <div className="bg-white text-dark pb-5">
                    <Container fluid="xl" className="py-2 px-0">
                        <Carousel
                            id="slideshow"
                            className="carousel slide mt-n6 mx-xl-n4 mb-5"
                            indicators={false}
                            controls
                            activeIndex={selectedPhoto}
                            onSelect={handleCarouselSlide}
                            prevIcon={<FiChevronLeft size={42} color="black" />}
                            nextIcon={<FiChevronRight size={42} color="black" />}
                        >
                            <Carousel.Item>
                                <img src={modernBuilding1} className="d-block w-100" alt="First slide" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={modernBuilding2} className="d-block w-100" alt="Second slide" />
                            </Carousel.Item>
                            {/* <Carousel.Item>
                                <img src={modernBuilding3} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
                            {/* <Carousel.Item>
                                <img src={modernBuilding4} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
                            {/* <Carousel.Item>
                                <img src={modernBuilding5} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
                            {/* <Carousel.Item>
                                <img src={modernBuilding6} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
                            {/* <Carousel.Item>
                                <img src={modernBuilding7} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
                            <Carousel.Item>
                                <img src={modernBuilding8} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item>
                            {/* <Carousel.Item>
                                <img src={modernBuilding9} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
                            <Carousel.Item>
                                <img src={modernBuilding10} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item>
                            {/* <Carousel.Item>
                                <img src={modernBuilding11} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
                            <Carousel.Item>
                                <img src={modernBuilding12} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item>

                        </Carousel>
                    </Container>

                    <Container>
                        <div className="row mb-5">
                            <div className="col-lg-4">
                                <dl className="mt-1">
                                    <dt className="fw-light">PROJECT STATUS</dt>
                                    <dd>Construction in progress</dd>
                                    <dt className="fw-light">LOCATION</dt>
                                    <dd>Coimbatore, Tamil Nadu, India</dd>
                                    <dt className="fw-light">SITE  AREA</dt>
                                    <dd>1.3 Acres (56628 Sq.Ft)</dd>
                                    <dt className="fw-light">FLOORS</dt>
                                    <dd>G+3 </dd>
                                </dl>
                            </div>
                            {/* <div className="col-lg-8 col-xxl-7">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu <a href="#/">fugiat nulla</a> pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div> */}
                            <div className="col-lg-8 col-xxl-7">
                                {selectedPhoto === 0 && (
                                    <div>
                                        <h5>SPERO HOSPITAL</h5>
                                        <ul>
                                            <li className="text-muted">
                                                A private, multi-specialty hospital titled SPERO HOSPITAL is located on the outskirts of Coimbatore, Tamil
                                                Nadu.
                                            </li>
                                            <li className="text-muted">
                                                The hospital was designed to be constructed on a rectangular parcel of land, with a focus on ensuring
                                                patient comfort and hospital staff convenience.
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                {selectedPhoto === 1 && (
                                    <div>
                                        <h5>FRONT ELEVATION</h5>
                                        <ul>
                                            <li className="text-muted">Brick, concrete, glass, marble, and mild steel are among the materials employed.</li>
                                            <li className="text-muted">
                                                A linear line of the front elevation is covered by a wooden pergola which is also the entry and exit path
                                                for the ambulance.
                                            </li>
                                            <li className="text-muted">
                                                The curtain wall glazing provided is anon-structural fabricated aluminium cladding system with glazing.
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                {selectedPhoto === 2 && (
                                    <div>
                                        <h5>SIDE ELEVATION - NORTH</h5>
                                        <ul>
                                            <li className="text-muted">
                                                This is more of the service side, where (Starting from Left) the stairway and waiting room are enclosed in
                                                glass panels, followed by the pedestrian and stretcher entry.
                                            </li>
                                            <li className="text-muted">The stretcher ramp follows the electrical services room and has a similar glass panel.</li>
                                            <li className="text-muted">This side is where the staff members (excluding doctors) have their drop off points.</li>
                                        </ul>
                                    </div>
                                )}
                                {selectedPhoto === 3 && (
                                    <div>
                                        <h5>45 DEGREE VIEW</h5>
                                        <ul>
                                            <li className="text-muted">
                                                The Client’s brief was to create a single massing that sat along the existing rectangular site lines, with
                                                minimal ornamentation and maximise the functionality.
                                            </li>
                                            <li className="text-muted">The stretcher ramp follows the electrical services room and has a similar glass panel.</li>
                                        </ul>
                                    </div>
                                )}
                                {selectedPhoto === 4 && (
                                    <div>
                                        <h5>SIDE ELEVATION</h5>
                                        <ul>
                                            <li className="text-muted">The pedestrian entrance and the stretcher entry are linked to the structure's entrance, starting from the left of the image.</li>
                                            <li className="text-muted">The double-height waiting area along with a ramp is on the other side of the glazing, along with emergency staircase, toilets and lift shafts.</li>
                                            <li className="text-muted">Opposite to the above stated areas are the several consultation rooms that are positioned around its perimeter.</li>
                                        </ul>
                                    </div>
                                )}



                                {/* Add more conditions for additional photos */}
                            </div>
                        </div>

                        {/* Projects navigation */}
                        <div className="d-flex justify-content-between align-content-center lh-1">
                            <Link to="#" className="link-body-emphasis" data-bs-toggle="tooltip" title="Previous project">

                                <span className="visually-hidden">Previous project</span>
                            </Link>
                            <Link to="/portfolio-1" className="link-body-emphasis" data-bs-toggle="tooltip" title="Projects page">

                            </Link>
                            <Link to="#" className="link-body-emphasis" data-bs-toggle="tooltip" title="Next project">

                                <span className="visually-hidden">Next project</span>
                            </Link>
                        </div>
                    </Container>
                </div>
            </main>

            <div className="text-center">
                {/* Other components */}
                <h2>Technical Drawings</h2>
                <Container><PhotoGallery /></Container>
               
                {/* Other components */}
            </div>


            <Footer></Footer>
        </div>
    );
}

export default ModernBuildingPage;

