import { useState } from 'react';
import { Carousel2 } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Modal } from 'react-bootstrap';
import { Container, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import PhotoGalleryC from '../components/PhotoGalleryC.js';

import modernBuilding1 from '../img/condo/CD_01.jpeg';
import modernBuilding2 from '../img/condo/CD_02.jpeg';
import modernBuilding3 from '../img/Spero/Spero_3 Resized.jpg';
import modernBuilding4 from '../img/Spero/Spero_4.jpg'; //this
import modernBuilding5 from '../img/Spero/Spero_5.jpg'; //this
import modernBuilding6 from '../img/Spero/Spero_6.jpg'; //this
import modernBuilding7 from '../img/Spero/Spero_7.jpg';
import modernBuilding8 from '../img/condo/CDF_03.jpeg';
import modernBuilding9 from '../img/Spero/Spero_9.jpg';
import modernBuilding10 from '../img/condo/CDF_04.jpeg';
import modernBuilding11 from '../img/Spero/Spero_11.jpg';
import modernBuilding12 from '../img/condo/CDF_05.jpeg';

import Footer from '../components/Footer.js';




function Condo() {

  const [selectedPhoto, setSelectedPhoto] = useState(0);

  const handleCarouselSlide = (selectedIndex) => {
    setSelectedPhoto(selectedIndex);
  };


  return (
    <div>
      <a href="/" className="visually-hidden-focusable">Skip to main content</a>
      <header className="sticky-top ">
        <nav className="navbar navbar-dark navbar-expand-lg bg-white " aria-label="Main">
          <div className="container">
            <Link to="/" className="navbar-brand d-inline-flex text-uppercase text-black">Design by Lohitha<i className="material-icons ms-2" aria-hidden="true"></i></Link>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
              <i className="material-icons icon-2x" aria-hidden="true">menu</i>
            </button>
            <div className="collapse navbar-collapse text-uppercase" id="navbar">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item text-black">
                  <Link to="/aboutme" className="nav-link text-black">About Me</Link>
                </li>
                <li className="nav-item text-black">
                  <Link to="/" className="nav-link text-black">Projects</Link>
                </li>

                <li className="nav-item text-black">
                  <Link to="/" className="nav-link text-black">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <main id="main">
        {/* Header */}
        <div className="container py-5 py-lg-6">
          <h6 className="display-6 mb-4 mb-md-5">A Condo in the City</h6>
        </div>


        {/* Project */}
        <div className="bg-white text-dark pb-5">
          <Container fluid="xl" className="py-2 px-0">
            <Carousel
              id="slideshow"
              className="carousel slide mt-n6 mx-xl-n4 mb-5"
              indicators={false}
              controls
              activeIndex={selectedPhoto}
              onSelect={handleCarouselSlide}
              prevIcon={<FiChevronLeft size={42} color="black" />}
              nextIcon={<FiChevronRight size={42} color="black" />}
            >
              <Carousel.Item>
                <img src={modernBuilding1} className="d-block w-75" alt="First slide" />
              </Carousel.Item>
              <Carousel.Item>
                <img src={modernBuilding2} className="d-block w-75" alt="Second slide" />
              </Carousel.Item>
              {/* <Carousel.Item>
                                <img src={modernBuilding3} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
              {/* <Carousel.Item>
                                <img src={modernBuilding4} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
              {/* <Carousel.Item>
                                <img src={modernBuilding5} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
              {/* <Carousel.Item>
                                <img src={modernBuilding6} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
              {/* <Carousel.Item>
                                <img src={modernBuilding7} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
              <Carousel.Item>
                <img src={modernBuilding8} className="d-block w-75" alt="Third slide" />
              </Carousel.Item>
              {/* <Carousel.Item>
                                <img src={modernBuilding9} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
              <Carousel.Item>
                <img src={modernBuilding10} className="d-block w-75" alt="Third slide" />
              </Carousel.Item>
              {/* <Carousel.Item>
                                <img src={modernBuilding11} className="d-block w-100" alt="Third slide" />
                            </Carousel.Item> */}
              <Carousel.Item>
                <img src={modernBuilding12} className="d-block w-75" alt="Third slide" />
              </Carousel.Item>

            </Carousel>
          </Container>

          <Container>
            <div className="row mb-5 ">
              <div className="col-lg-4">
                <dl className="mt-1">
                  <dt className="fw-light">PROJECT TYPE</dt>
                  <dd>Small Scale Accomodation</dd>

                  <dt className="fw-light">PROJECT AREA</dt>
                  <dd>100 Sq.M.</dd>
                  <dt className="fw-light">PROJECT STATUS</dt>
                  <dd>Finalised</dd>
                  <dt className="fw-light">PROJECT YEAR</dt>
                  <dd>2022</dd>
                </dl>
              </div>
              {/* <div className="col-lg-8 col-xxl-7">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu <a href="#/">fugiat nulla</a> pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div> */}
              <div className="col-lg-8 col-xxl-7">
                {selectedPhoto === 0 && (
                  <div className="mb-4"> {/* Add Bootstrap margin class for spacing */}
                    <h5>Image 1</h5>
                    <p className="text-muted">
                      Project brief was to create a cozy yet functional space for a student who wanted a space of their own, with all amenities a full-fledged residence would comprise of.
                    </p>
                  </div>
                )}
                {selectedPhoto === 1 && (
                  <div className="mb-4">
                    <h5>Image 2</h5>
                    <p className="text-muted">
                      This Condo is divided vertically by a mezzanine that houses the open resting area with a small study and cupboards, sufficient for a student.
                    </p>
                  </div>
                )}
                {selectedPhoto === 2 && (
                  <div className="mb-4">
                    <h5>Image 3</h5>
                    <p className="text-muted">
                      The colour palette chosen comprises of Black, Oakwood, Gray and white. This combination was chosen to give a more sophisticated yet cozy finish instead of bright colours.
                    </p>
                  </div>
                )}
                {selectedPhoto === 3 && (
                  <div className="mb-4">
                    <h5>Image 4</h5>
                    <p className="text-muted">
                      The bathroom was equipped with the shower cubicle, a WC, wash basin as well as a washing machine adjacent to the basin. A linear skylight was provided to increase the lighting levels into the bathroom without artificial lights.
                    </p>
                  </div>
                )}
                {selectedPhoto === 4 && (
                  <div className="mb-4">
                    <h5>Image 5</h5>
                    <p className="text-muted">This Condo also opens out into a balcony with an arched fireplace and a recliner chair, in the same colour scheme, which are further adorned by multiple potted plants that add a pop of colour.</p>
                  </div>
                )}

                {/* Add more conditions for additional photos */}
              </div>

            </div>

            {/* Projects navigation */}
            <div className="d-flex justify-content-between align-content-center lh-1">
              <Link to="#" className="link-body-emphasis" data-bs-toggle="tooltip" title="Previous project">

                <span className="visually-hidden">Previous project</span>
              </Link>
              <Link to="/portfolio-1" className="link-body-emphasis" data-bs-toggle="tooltip" title="Projects page">

              </Link>
              <Link to="#" className="link-body-emphasis" data-bs-toggle="tooltip" title="Next project">

                <span className="visually-hidden">Next project</span>
              </Link>
            </div>
          </Container>
        </div>
      </main>

      <div className="text-center">
        {/* Other components */}
        <h2>Technical Drawings</h2>
        <Container><PhotoGalleryC /></Container>

        {/* Other components */}
      </div>


      <Footer></Footer>
    </div>
  );
}

export default Condo;

